import React, { useEffect, useState } from 'react';
import sampleImage from '../assets/JosueGarcia.png'; // Asegúrate de que la ruta sea correcta

const ImageToBase64 = ({ onConvert }) => {
  const [imageBase64, setImageBase64] = useState('');

  useEffect(() => {
    const toBase64 = (url) => 
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }));

    toBase64(sampleImage).then(base64 => {
      setImageBase64(base64);
      onConvert(base64);
    });
  }, [onConvert]);

  return imageBase64 ? <img src={imageBase64} alt="Converted" style={{ width: '100px', height: '100px' }} /> : null;
};

export default ImageToBase64;
