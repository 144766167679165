import React from 'react';
import EmailIcon from '@mui/icons-material/Email'; // Importa el ícono de correo electrónico de la biblioteca @mui/icons-material
import PhoneIcon from '@mui/icons-material/Phone'; // Importa el ícono de teléfono de la biblioteca @mui/icons-material
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importa el ícono de WhatsApp de la biblioteca @mui/icons-material


function ContactInfo({ email, phone, whatsapp, logoUrl, street, city, country, website }) {
  return (
    <div className="contactInfo">
      <div className="contactInfo">
        <p style={{ margin: '5px 0' }}>
          <EmailIcon style={{ verticalAlign: 'middle', color: '#00AEEF', marginRight: '20px' }} />
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p style={{ margin: '5px 0' }}>
          <PhoneIcon style={{ verticalAlign: 'middle', color: '', marginRight: '20px' }} />
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
        <p style={{ margin: '5px 0' }}>
          <WhatsAppIcon style={{ verticalAlign: 'middle', color: 'green', marginRight: '20px' }} />
          <a href={`https://wa.me/34644661000`} style={{ color: 'green' }}>{whatsapp}</a>
        </p>
      </div>
      <img src={logoUrl} alt="GRUTIC Logo" className="companyLogo" style={{ borderRadius: '0', width: '180px', height: '35px', marginTop: '20px' }} /> {/* Aplicar un borde cuadrado y otros estilos */}
      <p style={{ lineHeight: '0.5' }}>{street}</p>
      <p style={{ lineHeight: '0.5' }}>{city}</p>
      <p style={{ lineHeight: '0.5' }}>{country}</p>
      <p style={{ lineHeight: '2' }}><a href={website}>{website}</a></p>
    </div>
  );
}

export default ContactInfo;
