import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Archivo de configuración de i18n
//import GruticLogo from './assets/GRUTIC_logo_land.png'; // Importa el logo de Grutic
import LanguageSelector from './components/LanguageSelector'; // Importa el componente LanguageSelector
import ProfileCard from './components/ProfileCard';
import './App.css';

function App() {
  // Cambiar el título de la página
  document.title = 'GRUTIC - Josué García Rodríguez';
  
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
      <div className="contentWrapper">
      {/*<div className="logoWrapper">
          <img src={GruticLogo} alt="Grutic Logo" className="gruticLogo" />
        </div>*/}
        <LanguageSelector /> {/* Agrega el selector de idioma */}
        <ProfileCard />
        </div>
      </div>
    </I18nextProvider>
  );
}

export default App;
