import React from 'react';
import { useTranslation } from 'react-i18next';
import usaFlag from '../assets/usukflag.png'; // Importa la imagen de la bandera de EE. UU.
import spainFlag from '../assets/esflag.png'; // Importa la imagen de la bandera de España.
import catFlag from '../assets/catflag.png'; // Importa la imagen de la bandera de España.
import './LanguageSelector.css'; // Importa el archivo CSS para el componente LanguageSelector

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="languageSelector">
      <button className="languageButton" onClick={() => changeLanguage('en')}>
        <img src={usaFlag} alt="English" />
      </button>
      <button className="languageButton" onClick={() => changeLanguage('es')}>
        <img src={spainFlag} alt="Español" />
      </button>
      <button className="languageButton" onClick={() => changeLanguage('cat')}>
        <img src={catFlag} alt="Català" />
      </button>
    </div>
  );
}

export default LanguageSelector;
