import React from 'react';
import { Facebook, LinkedIn, Instagram } from '@mui/icons-material';

function SocialLinks() {
  return (
    <div className="socialLinks">
      <a href="https://www.facebook.com/gruticbcn"><Facebook /></a>
      <a href="https://www.linkedin.com/company/grutic/"><LinkedIn /></a>
      <a href="https://www.instagram.com/gruticbcn/"><Instagram /></a>
    </div>
  );
}

export default SocialLinks;
