import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa los archivos de idioma
import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationCAT from './locales/cat.json';

// Configura i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
      cat: {
        translation: translationCAT,
      },
    },
    lng: 'en', // Idioma predeterminado
    fallbackLng: 'en', // Idioma de fallback
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
