import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import favicon from './assets/favicon.ico'; // Ruta al archivo de ícono
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Establecer el favicon dinámicamente
const setFavicon = () => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = favicon;
  document.head.appendChild(link);
};

setFavicon();
